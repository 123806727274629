

export default {
    name: 'QueryCard',
    props: {
        record: {
            type: Object,
            default: () => ({
                text: '',
            }),
        },
    },
}
